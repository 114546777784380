import { Component, OnInit } from '@angular/core';
import { User } from '../models/index'

@Component({
  selector: 'app-myaccount',
  templateUrl: './myaccount.component.html',
  styleUrls: ['./myaccount.component.css']
})
export class MyaccountComponent implements OnInit {
isLoggedIn:any;
user:User;
  constructor() { }

  ngOnInit(): void {
    this.isLoggedIn = localStorage.getItem('isLoggedIn');
    if(this.isLoggedIn ){
      this.user=new User().deserialize(JSON.parse(localStorage.getItem('user')))
      console.log(this.user);
    }
  }

}
