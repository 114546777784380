<div class="tabbable ">
    <div class="tab-content _pt-15 _ph-15 _pt-10  _bg-w tab-content-curved">
      <!-- <img  src="assets/img/company1/logo-name.png" alt="buupass.com"  height="30" alt="Image Alternative text" title="Image Title"/> -->
      <div class="text-center">
        <h4>Welcome to BuuPass</h4>
        <p>The easiest way to book bus tickets in Kenya.</p>
      </div>
        <div class="row">
          <div class="col-md-2">
            <div class="_mb-10">
              <span class="switch-item">
                <label for="one-way-switch" class="switch">
                  <input id="one-way-switch" (click)="isOneWayClicked()" type="radio" name="switch-choice" [checked]="!returning" >
                  <span class="slider round"></span>
                </label>
                <span class="checkbox-title _ml-10">One Way</span>
              </span>
              <span class="switch-item">
                <label for="return-trip-switch" class="switch">
                <input id="return-trip-switch" (click)="isReturnTripClicked()" type="radio" name="switch-choice" [checked]="returning" >
                  <span class="slider round"></span>
                </label>
                <span class="checkbox-title _ml-10">Return trip</span>
              </span>
            </div>
          </div>
        </div>
        <div class="tab-pane active" id="SearchAreaTabs-4">
            <div class="theme-search-area">
                <div class="theme-search-area-form">
                <form  autocomplete="off" novalidate [formGroup]="reactiveForm" (ngSubmit)="submitForm()">
                    <div class="row" data-gutter="10">
                        <div class="col-md-7 ">
                            <div class="row" data-gutter="10">
                                <div class="col-md-5 ">
                                    <div class="theme-search-area-section first theme-search-area-section-border-light theme-search-area-section-curved">
                                        <div class="theme-search-area-section-inner">
                                            <i  class="theme-search-area-section-icon fa fa-map-marker-alt"></i>
                                            <input  (click)="depaturesClicked()" formControlName="pickup" class="theme-search-area-section-input typeahead" type="text"
                                                placeholder="From" data-provide="typeahead">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <button aria-label="interchange" class="Route_Button Route_Button_padd RouteSwitcher"
                                    tabindex="-1">
                                    <i class="fas fa-exchange-alt RouteSwitcher_icon text-white"></i>
                                    </button>
                                </div>
                                <div class="col-md-5 ">
                                    <div class="theme-search-area-section theme-search-area-section-border-light theme-search-area-section-curved">
                                        <div class="theme-search-area-section-inner">
                                            <i  class="theme-search-area-section-icon fa fa-map-marker-alt"></i>
                                            <input  (click)="destinationsClicked()"  formControlName="dropoff" class="theme-search-area-section-input typeahead"
                                             type="text"
                                            placeholder="To"
                                             data-provide="typeahead">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 ">
                            <div
                                class="theme-search-area-section theme-search-area-section-border-light theme-search-area-section-curved">
                                <div class="theme-search-area-section-inner">
                                    <i _ngcontent-dlj-c151="" class="theme-search-area-section-icon fas fa-calendar-alt ng-tns-c151-2"></i>
                                    <input (click)="depatureDateClicked()" class="theme-search-area-section-input datePickerStart "
                                    formControlName="mobiletraveldate" type="text" placeholder="Depature">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2" *ngIf="returning">
                            <div class="theme-search-area-section theme-search-area-section-border-light theme-search-area-section-curved">
                                <div class="theme-search-area-section-inner">
                                    <i _ngcontent-dlj-c151="" class="theme-search-area-section-icon fas fa-calendar-alt ng-tns-c151-2"></i>
                                    <input (click)="returnDateClicked()" class="theme-search-area-section-input datePickerEnd "
                                    formControlName="mobilereturndate"  type="text" placeholder="Return (optional)">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-1 ">
                            <button  class="theme-search-area-submit theme-search-area-submit-curved">Search</button>
                        </div>
                    </div>
                </form>
                </div>
            </div>
        </div>
    </div>
</div>
