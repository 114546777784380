import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PaymentsComponent } from './payments/payments.component';
import { HomeComponent } from './home/home.component';
import { BookingComponent } from './booking/booking.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { SuccessComponent } from './success/success.component';
import { ContactusComponent } from './contactus/contactus.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { DepatureDateComponent } from './mobile/depature-date/depature-date.component';
import { ReturnDateComponent } from './mobile/return-date/return-date.component';
import { MobileFromComponent } from './mobile/mobile-from/mobile-from.component';
import { MobileToComponent } from './mobile/mobile-to/mobile-to.component';
import { ReviewPayComponent } from './review-pay/review-pay.component';
import { MyaccountComponent } from './myaccount/myaccount.component';
import { MynotificationsComponent } from './mynotifications/mynotifications.component';
import { MytripsComponent } from './mytrips/mytrips.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home/:id', component: HomeComponent, data: {animation: 'Home'}, },
  { path: 'booking',      component: BookingComponent, data: {animation: 'Booking'}, },
  { path: 'payments/:id',      component: PaymentsComponent, data: {animation: 'Payments'}, },
  { path: 'booking-successful/:id',      component: SuccessComponent, data: {animation: 'Success'}, },
  { path: 'review-and-pay/:id',      component: ReviewPayComponent, data: {animation: 'Success'}, },
  { path: 'confirm-payments/:id',      component: ConfirmComponent, data: {animation: 'Confirm'}, },
  { path: 'about-us',      component: AboutusComponent, data: {animation: 'About'}, },
  { path: 'contact-us',      component: ContactusComponent, data: {animation: 'COntact'}, },
  { path: 'depature-date',      component: DepatureDateComponent, data: {animation: 'Destination'}, },
  { path: 'return-date',      component: ReturnDateComponent, data: {animation: 'Destination'}, },
  { path: 'mobile-from',      component: MobileFromComponent, data: {animation: 'Destination'}, },
  { path: 'mobile-to',      component: MobileToComponent, data: {animation: 'Destination'}, },
  { path: 'account',      component: MyaccountComponent, data: {animation: 'Home'}, },
  { path: 'my-notifications',      component: MynotificationsComponent, data: {animation: 'Home'}, },
  { path: 'my-trips',      component: MytripsComponent, data: {animation: 'Home'}, },
  { path: '**', component: HomeComponent },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    scrollOffset: [0, 0],
      // Enable scrolling to anchors
      anchorScrolling: "enabled",
    relativeLinkResolution: 'legacy'
 })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
