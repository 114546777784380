

<app-headerhome  class="visible-lg"></app-headerhome>
<div id="tophome" class="row hidden-sm hidden-md hidden-lg" >
  <div class="col-lg-12 bg-grey text-white stuck">
      <div class="input-group _p-10">
        <span class="input-group-btn">
          <button (click)="backHome()" class="theme-search-area-submit theme-search-area-submit-curved buupass-orange-bg" type="button">
              <i class="fas fa-chevron-left"></i>
          </button>
        </span>
          <input (click)="backHome()" class="form-control-search" id="white-placeholder" class="theme-search-area-section-input buupass-pink-light-bg _pl-15"
           type="text"
           type="text"
           placeholder="Search"
           >
      </div>
  </div>
</div>
<div id="top"></div>
<div class=" theme-page-section-buupass-pd buupass-container " >
          <div class="theme-search-area _mob-h _pt-15 _ph-25 _pt-10 _pb-30 ">
            <div class="row">
              <div class="col-md-12">
                <div class="theme-search-area " >
                    <form  autocomplete="off" novalidate [formGroup]="reactiveForm" (ngSubmit)="submitWebForm()">
                        <div class="row" >
                              <div class="col-md-3">
                                  <app-from formControlName="pickup"
                                    [options]="data"
                                    [depature]="pickUpInit"
                                    [ngClass]="{ 'ng-invalid-custom': submitted && f.pickup.errors }"
                                    [isReadOnly]="isReadOnly">
                                  </app-from>
                                  <span *ngIf="submitted && f.pickup.errors" class="invalid-select">please select pickup location</span>
                              </div>
                              <div class="col-md-1">
                                <div (click)="interChange()" class=" _mt-20 SwitchDirectionsStyles__StyledSwitchDirections-sc-14fk104-2 lcGjiX"><span
                                    data-test="SwitchDirections-inner" role="button" aria-label="Switch direction" tabindex="0"
                                    class="SwitchDirectionsStyles__SwitchDirectionsLink-sc-14fk104-1 dnQkMW">
                                    <svg
                                        class="Icon__StyledIcon-sc-1pnzn3g-0 bRGQbR" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                                        <path
                                            d="M5.396 6.282l2.922 2.922a.554.554 0 0 1 .182.426c0 .366-.243.609-.609.609L5.9 10.238V14.5a2.1 2.1 0 0 0 1.95 2.095L8 16.6h7.275a.9.9 0 0 1 .113 1.793l-.113.007H8a3.9 3.9 0 0 1-3.895-3.7l-.005-.2v-4.262H2.109a.535.535 0 0 1-.353-.116l-.073-.066a.588.588 0 0 1 0-.852l2.921-2.922c.244-.243.609-.243.792 0zm10.6-.682a3.9 3.9 0 0 1 3.894 3.7l.005.2v4.262h1.992c.146 0 .253.038.352.116l.074.066a.588.588 0 0 1 0 .852l-2.922 2.922c-.243.243-.609.243-.791 0l-2.923-2.922a.554.554 0 0 1-.182-.426c0-.366.244-.609.61-.609l1.99.001V9.5a2.1 2.1 0 0 0-1.95-2.095l-.15-.005H8.72a.9.9 0 0 1-.113-1.793L8.72 5.6h7.275z">
                                        </path>
                                    </svg>
                                </span>
                                </div>
                             </div>
                              <div class="col-md-3">
                                  <app-dropdown
                                    formControlName="dropoff"
                                    [dropdownoptions]="data"
                                    [ngClass]="{ 'ng-invalid-custom': submitted && f.dropoff.errors }"
                                    [isReadOnly]="isReadOnly">
                                  </app-dropdown>
                                  <span *ngIf="submitted && f.dropoff.errors" class="invalid-select">please select dropoff location</span>
                              </div>
                              <div class="col-md-2">
                                <div class="theme-search-area-section first theme-search-area-section-curved theme-search-area-section-bg-white  theme-search-area-section-mr">
                                  <div class="theme-search-area-section-inner">
                                    <i class="theme-search-area-section-icon fas fa-calendar-alt"></i>
                                    <input type="text"
                                    formControlName="traveldate"
                                    placeholder="Travel Date"
                                    (bsValueChange)="onTravelDateChange($event)"
                                    class="theme-search-area-section-input "
                                    [minDate]="today"
                                    bsDatepicker
                                    [bsValue]="bsValue"
                                    [bsConfig]="{ isAnimated: true,adaptivePosition: true, dateInputFormat: 'DD-MM-YYYY' }"
                                    >
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-2 ">
                              <div class="theme-search-area-section theme-search-area-section-curved theme-search-area-section-fade-white">
                                <div class="theme-search-area-section-inner theme-search-area-section-inner-return">

                                          <div *ngIf="returning" class="theme-search-area-section-inner returndate">
                                            <i class="theme-search-area-section-icon fas fa-calendar-alt"></i>
                                            <i [ngClass]="( isClicked ) ? 'hide-returnicon':''" class="theme-search-area-section-icon lin lin-calendar "></i>
                                            <input required autocomplete="off" type="text"
                                            placeholder="dd-mm-yyyy"
                                            class="theme-search-area-section-input"
                                            formControlName="returndate"
                                            [minDate]="today"
                                            #dp="bsDatepicker"
                                            [isOpen]="isOpen"
                                            bsDatepicker
                                            (bsValueChange)="onReturnDateChange($event)"
                                            [bsConfig]="{ isAnimated: true,adaptivePosition: true, dateInputFormat: 'DD-MM-YYYY' }"
                                            [bsValue]="bsInlineValue">
                                            <i (click)="closeReturn()" [ngClass]="( isClicked ) ? 'hide-returnicon':''"  class="times-icon  fa fa-times "></i>

                                  </div>
                                  <button *ngIf="!returning" (click)="returnClicked()" class="flix-btn-arrow DatePlaceholder__button___3XZ5p" type="button"><i class=" icon-flix fa fa-plus "></i> Return date
                                    </button>
                              </div>
                            </div>

                          </div>
                              <div class="col-md-1">
                                <button type="submit"  class="theme-search-area-submit  theme-search-area-submit-curved  theme-search-area-submit-glow">SEARCH</button>
                              </div>
                      </div>
                      </form>
               </div>

              </div>
            </div>
          </div>
</div>
<div class="bus-listing-page  searchFont header-displayed "  #floaded>
<div class="buupass-container">
    <div class="page-content row ">
        <div class=" u-ib  col-md-12" id="search-results-wrapper">
            <h2  class="city-labels-lg text-center  visible-lg ">
                    {{fromPlaceholder}}
                    →
                    {{toPlaceholder}}
                    <span *ngIf="isReturnTicket" class="theme-search-area-title text-center">
                        <svg class="Icon__StyledIcon-sc-1pnzn3g-0 bRGQbR" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                            <path
                                d="M5.396 6.282l2.922 2.922a.554.554 0 0 1 .182.426c0 .366-.243.609-.609.609L5.9 10.238V14.5a2.1 2.1 0 0 0 1.95 2.095L8 16.6h7.275a.9.9 0 0 1 .113 1.793l-.113.007H8a3.9 3.9 0 0 1-3.895-3.7l-.005-.2v-4.262H2.109a.535.535 0 0 1-.353-.116l-.073-.066a.588.588 0 0 1 0-.852l2.921-2.922c.244-.243.609-.243.792 0zm10.6-.682a3.9 3.9 0 0 1 3.894 3.7l.005.2v4.262h1.992c.146 0 .253.038.352.116l.074.066a.588.588 0 0 1 0 .852l-2.922 2.922c-.243.243-.609.243-.791 0l-2.923-2.922a.554.554 0 0 1-.182-.426c0-.366.244-.609.61-.609l1.99.001V9.5a2.1 2.1 0 0 0-1.95-2.095l-.15-.005H8.72a.9.9 0 0 1-.113-1.793L8.72 5.6h7.275z">
                            </path>
                         </svg>
                        Return Ticket
                        </span>
            </h2>
            <h2 class="city-labels-lg text-center hidden-sm hidden-md hidden-lg _pt-30">
                    {{fromPlaceholder}}
                    →
                    {{toPlaceholder}}
                    <span *ngIf="isReturnTicket" class="theme-search-area-title text-center">
                        <svg class="Icon__StyledIcon-sc-1pnzn3g-0 bRGQbR" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                            <path
                                d="M5.396 6.282l2.922 2.922a.554.554 0 0 1 .182.426c0 .366-.243.609-.609.609L5.9 10.238V14.5a2.1 2.1 0 0 0 1.95 2.095L8 16.6h7.275a.9.9 0 0 1 .113 1.793l-.113.007H8a3.9 3.9 0 0 1-3.895-3.7l-.005-.2v-4.262H2.109a.535.535 0 0 1-.353-.116l-.073-.066a.588.588 0 0 1 0-.852l2.921-2.922c.244-.243.609-.243.792 0zm10.6-.682a3.9 3.9 0 0 1 3.894 3.7l.005.2v4.262h1.992c.146 0 .253.038.352.116l.074.066a.588.588 0 0 1 0 .852l-2.922 2.922c-.243.243-.609.243-.791 0l-2.923-2.922a.554.554 0 0 1-.182-.426c0-.366.244-.609.61-.609l1.99.001V9.5a2.1 2.1 0 0 0-1.95-2.095l-.15-.005H8.72a.9.9 0 0 1-.113-1.793L8.72 5.6h7.275z">
                            </path>
                         </svg>
                        Return Ticket
                        </span>

            </h2>
            <hr class="hidden-sm hidden-md hidden-lg">
            <div  class="row visible-lg ">
                <div class="col-md-7 col-md-offset-2 theme-search-results search-result-multiselect hidden-sm">
                        <ul class="dates ">
                                <li class="prev-container">
                                    <a  class="prev" [routerLink]="" (click)="changeDate(beforeyesterday)" >
                                    ‹
                            </a></li>
                            <li  class="yesterday-container">
                                <a    [routerLink]="" class="yesterday"  (click)="changeDate(yesterday)">
                                    <strong>
                                            {{yesterday  | date:'EEEE, d MMM'}}
                                    </strong></a>
                            </li>
                            <li class="active-container"><a class="active" [routerLink]=""  (click)="changeDate(travelDateInit)" >
                                    <strong>
                                            {{travelDateInit  | date:'EEEE, d MMM'}}
                                    </strong></a></li>
                            <li class="tomorrow-container"><a class="tomorrow" [routerLink]="" (click)="changeDate(tomorrow)">
                                    <strong>
                                            {{tomorrow  | date:'EEEE, d MMM'}}
                                    </strong></a></li>
                            <li class="next-container"><a class="next" [routerLink]="" (click)="changeDate(aftertomorrow)">
                                    ›
                                </a></li>
                        </ul>
                </div>

            </div>
            <div class="row ">
                <div class="col-md-2 ">
                    <div class="sticky-col _mob-h">
                        <div class="theme-search-results-sidebar ">
                            <div class="theme-search-results-sidebar-sections _mb-20 _br-2 theme-search-results-sidebar-sections-no-white-wrap">
                                <div class="theme-search-results-sidebar-section">
                                    <h5 class="theme-search-results-sidebar-section-title">Bus Type</h5>
                                    <!-- Rounded switch -->
                                    <div class="_mb-10">
                                      <label class="switch">
                                        <input type="checkbox">
                                        <span class="slider round"></span>
                                      </label>
                                      <span class="checkbox-title _ml-10">Executive</span>
                                    </div>
                                    <div class="_mb-10">
                                      <!-- Rounded switch -->
                                        <label class="switch">
                                          <input type="checkbox">
                                          <span class="slider round"></span>
                                        </label>
                                        <span class="checkbox-title _ml-10">VIP</span>
                                    </div>
                                    <div class="_mb-10">
                                      <label class="switch">
                                        <input type="checkbox">
                                        <span class="slider round"></span>
                                      </label>
                                      <span class="checkbox-title _ml-10">Business</span>
                                    </div>
                                    <div class="_mb-10">
                                      <label class="switch">
                                        <input type="checkbox">
                                        <span class="slider round"></span>
                                      </label>
                                      <span class="checkbox-title _ml-10">Normal</span>
                                    </div>

                                </div>
                                <div class="theme-search-results-sidebar-section">
                                    <h5 class="theme-search-results-sidebar-section-title">Facilities</h5>
                                    <!-- Rounded switch -->
                                    <div class="_mb-10">
                                      <label class="switch">
                                        <input type="checkbox">
                                        <span class="slider round"></span>
                                      </label>
                                      <span class="checkbox-title _ml-10">AC</span>
                                    </div>
                                    <div class="_mb-10">
                                      <!-- Rounded switch -->
                                        <label class="switch">
                                          <input type="checkbox">
                                          <span class="slider round"></span>
                                        </label>
                                        <span class="checkbox-title _ml-10">Water</span>
                                    </div>
                                </div>
                                <div class="fltr-col u-ib">
                                    <div class="fltr ">
                                        <h5 class="theme-search-results-sidebar-section-title">Timing</h5>
                                        <div class="fltr-cntnt">
                                            <div class="tmng-btn-cntnr">
                                                <div class="tmng-btn">
                                                    <div class="lbl">Morning</div>
                                                    <button class="c-btn u-link secondary enabled">
                                                        <div class="u-ripple"><span class="u-ripple-circle"></span></div>5 AM to 12 PM
                                                    </button>
                                                </div>
                                                <div class="tmng-btn">
                                                    <div class="lbl">Afternoon</div>
                                                    <button class="c-btn u-link secondary enabled">
                                                        <div class="u-ripple"><span class="u-ripple-circle"></span></div>12 PM to 5 PM
                                                    </button>
                                                </div>
                                                <div class="tmng-btn">
                                                    <div class="lbl">Evening</div>
                                                    <button class="c-btn u-link secondary enabled">
                                                    <div class="u-ripple"><span class="u-ripple-circle"></span></div>5 PM to 10 PM
                                                    </button>
                                                </div>
                                                <!-- <div class="tmng-btn">
                                                    <div class="lbl">Overnight</div>
                                                    <button class="c-btn u-link secondary enabled">
                                                        <div class="u-ripple"><span class="u-ripple-circle"></span></div>10 PM to 5 AM
                                                    </button>

                                                </div> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class=" col-md-7 theme-search-results search-result-multiselect">
                <div class="search-result" id="search-result-direct">
                    <div class="  rides-list currency-eur">
                        <div id="results-group-container-direct" class="result-date-container">
                                <div class=" ride-item-pair  row"  *ngIf="isBusSelected">
                                    <div class="col-xs-12">
                                        <div class="sr-row row u-box  ride--booked js-ride--booked" >
                                            <div class="col-xs-5  time-transfer">
                                                <ul class="theme-search-results-item-car-list">
                                                    <li>
                                                      <i class="fa fa-check-circle"></i>Selected Depature
                                                    </li>
                                                </ul>
                                                    <div  class=" ride-stations">
                                                        <div class="duration ride__duration ride__duration-messages">
                                                            <strong> {{travelDateInit  | date:'EEEE, d MMM'}} {{ busSelected.departure_time}} </strong>
                                                            <span class=" ride__duration-suffix duration-suffix">Hrs.</span>
                                                        </div>
                                                        <div class="bus-info u-ib u-v-align-middle">
                                                            <h6 class="left-wing u-ib _mr-10">{{ bookingdetails.pickupname}}</h6>
                                                            <div class="timeline-widget u-ib">
                                                                <div class="c-timeline-wrapper horizontal">
                                                                    <div class="label tl first" style="font-size: 12px;"></div>
                                                                    <div class="label tl last" style="font-size: 12px;"></div>
                                                                    <div class="c-timeline-booking"
                                                                        style="height: 2px; background-color: rgb(187, 187, 187);">
                                                                        <div class="dot"
                                                                            style="width: 6px; height: 6px; border-radius: 6px; background-color: rgb(117, 117, 117); margin-top: -2px;">
                                                                        </div>
                                                                        <div class="dot"
                                                                            style="width: 6px; height: 6px; border-radius: 6px; background-color: rgb(117, 117, 117); margin-top: -2px;">
                                                                        </div>
                                                                    </div>
                                                                    <div class="label br first" style="font-size: 12px;"></div>
                                                                    <div class="label br " style="font-size: 12px;"></div>
                                                                    <div class="label br last" style="font-size: 12px;"></div>
                                                                </div>
                                                            </div>
                                                            <div class="left-wing u-ib _ml-10">{{ bookingdetails.dropoffname}}</div>
                                                        </div>
                                                    </div>
                                            </div>
                                            <div  class="col-xs-6">
                                                <div class="row">
                                                    <div class=" col-lg-6">
                                                        <div class="Badge-Bus _mt-40 _ml-10"> {{busSelected.operator.name}} </div>
                                                    </div>
                                                    <div  class="col-lg-6 price-actions">
                                                        <div class="duration ride__duration ride__duration-messages _mt-40">
                                                            <span class=" ride__boarding-point duration-suffix">
                                                                <b *ngFor="let stations of busSelected.boarding_points; let m =index">
                                                                    <div *ngIf="m <= 1">
                                                                   {{stations.Name}}
                                                                    </div>
                                                                </b>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h2 *ngIf="isBusSelected" class="city-labels-return ">
                                    Return Trip
                                </h2>
                                <app-nobuses *ngIf="results.length == 0 && !isResultsLoading "></app-nobuses>
                                <app-results-loader *ngIf="isResultsLoading"></app-results-loader>
                            <div  *ngFor="let result of results;let i = index; trackBy: trackByBooking" class=" ride-item-pair  row" >
                                <div class="col-xs-12 results{{result.id}}">
                                    <div class="sr-row row u-box  ride--booked js-ride--booked" >
                                        <!-- Desktop -->
                                      <div  class="visible-lg">
                                        <div class="col-xs-3  time-transfer">
                                            <img class="mobile-images _mt-10" alt="{{result.operator.name}}" src="assets/img/images/{{result.operator.alias}}.png" >
                                            <br>
                                                <p class="Badge-Bus _mt-20"> {{result.operator.name}} </p>
                                        </div>
                                        <div  class="col-xs-6">
                                                <div  class="ride-stations">
                                                    <div class="duration ride__duration ride__duration-messages">
                                                         {{ bookingdetails.pickupname}}
                                                    </div>
                                                </div>
                                                  <div class="bus-info u-ib u-v-align-middle">
                                                      <h6 class="left-wing city-labels-list u-ib _mr-5">
                                                        {{ result.departure_time}}
                                                        <span class=" ride__duration-suffix duration-suffix">Hrs.</span>
                                                      </h6>
                                                      <div class="timeline-widget u-ib">
                                                          <div class="c-timeline-wrapper horizontal">
                                                              <div class="label tl first" style="font-size: 38px;"></div>
                                                              <div class="label tl last" style="font-size: 38px;"></div>
                                                              <div class="c-timeline-booking"
                                                                  style="height: 2px; background-color: rgb(187, 187, 187);">
                                                                  <div class="dot"
                                                                      style="width: 6px; height: 6px; border-radius: 6px; background-color: rgb(117, 117, 117); margin-top: -2px;">
                                                                  </div>
                                                                  <div class="dot"
                                                                      style="width: 6px; height: 6px; border-radius: 6px; background-color: rgb(117, 117, 117); margin-top: -2px;">
                                                                  </div>
                                                              </div>
                                                              <div class="label br first" style="font-size: 38px;"></div>
                                                              <div class="label br " style="font-size: 38px;"></div>
                                                              <div class="label br last" style="font-size: 38px;"></div>
                                                          </div>
                                                      </div>
                                                      <h6 class="right-wing city-labels-list u-ib _ml-5">
                                                        {{ result.arrival_time}}
                                                        <span class=" ride__duration-suffix duration-suffix">Hrs.</span>
                                                      </h6>
                                                  </div>
                                                  <div class="seats-notice text-center">
                                                          Remaining Seats {{result.number_of_available_seats}}
                                                  </div>
                                        </div>
                                        <div  class="col-lg-3 price-actions">
                                          <div  class="_mb-40 ride-stations">
                                              <div class="duration ride__duration ride__duration-messages">
                                                   {{ bookingdetails.dropoffname}}
                                              </div>
                                          </div>
                                                <p *ngFor="let seat of result.seats_types;let m = index; ">
                                                  <span class="theme-footer-section-title" *ngIf="m==0">
                                                   Ksh {{seat.fare}}
                                                  </span>
                                                </p>
                                                <button [ngClass]="{'disabled': hide_from_ui == result.id,'disabled': hide_from_ui == result.id}" type="submit" class="reserve" style="box-sizing: border-box;" #focusspinner (click)="selectSeats(result)">Select Seat</button>
                                        </div>
                                      </div>
                                        <!-- mobile -->
                                        <div  class="hidden-sm hidden-md hidden-lg">
                                          <div class="col-xs-3  time-transfer">
                                              <img class="mobile-images _mt-10" alt="{{result.operator.name}}" src="assets/img/images/{{result.operator.alias}}.png" >
                                              <div  class=" price-actions">
                                                  <p class="Badge-Bus "> {{result.operator.name}}</p>
                                              </div>
                                          </div>

                                          <div  class="col-xs-9">
                                                  <p>
                                                    <span class="pull-left city-labels-mobile">{{ bookingdetails.pickupname}}</span>
                                                    <span class="pull-right city-labels-mobile">{{ bookingdetails.dropoffname}}</span>
                                                  </p>
                                                  <div class="mobile-summary">
                                                    <div class="left-wing">
                                                      <div class="time">{{ result.departure_time}}</div>
                                                    </div><div class="separator u-lfloat">
                                                        →
                                                      <i class="ixi-icon-arrow"></i>
                                                    </div>
                                                    <div class="right-wing">
                                                      <div class="time">  {{ result.arrival_time}}</div>
                                                    </div><div class="u-clear">
                                                    </div>
                                                  </div>

                                          </div>
                                        </div>
                                          <div  class="col-md-10 hidden-sm hidden-md hidden-lg">
                                            <div class="_mt-91">
                                              <span class="seats-notice text-center">
                                                      Remaining Seats {{result.number_of_available_seats}}
                                              </span>

                                              <div class="pull-right">
                                                <p *ngFor="let seat of result.seats_types;let m = index; ">
                                                  <span class="theme-footer-section-title" *ngIf="m==0">
                                                   Ksh {{seat.fare}}
                                                  </span>
                                                </p>
                                                <button [ngClass]="{'disabled': hide_from_ui == result.id,'disabled': hide_from_ui == result.id}" type="submit" class="reserve" style="box-sizing: border-box;" #focusspinner (click)="selectSeats(result)">Select Seat</button>
                                              </div>
                                            </div>
                                        </div>
                                        <div #focustarget class="row">
                                            <div   class=" extra-info ">
                                                <div id="mySidenav" class="seat-book-cntr sidenav-booking "  [ngClass]="{'sidenav-booking-h':seatsSelected.length > 0,'sidenav-booking-n':!seatsSelected.length > 0}" >
                                                    <div class="seat-book-error"></div>
                                                    <h4 *ngIf="selectedBusStatus" class=" _mt-25 _mr-10 _mtm-25 text-center">

                                                        Select a seat ( {{selectedBus.operator.name}} )
                                                    </h4>
                                                    <div  class="center-wrapper ">
                                                        <div class="seat-layout-cntr" >
                                                            <div class="col-md-7">
                                                                <app-spinner  *ngIf="dataLoading && !seatErrors"></app-spinner>
                                                                <div  *ngIf="seatErrors" class="seat-book-error">
                                                                  <p> Unable to load seats for this bus. Kindly try a different bus. Thank you.
                                                                </div>
                                                                <div *ngIf="!dataLoading && !seatErrors" class="seat-layout">
                                                                    <div  class="seat-rows u-ib-sidebar">
                                                                            <div  class="bus-steering u-ib-sidebar pull-right">
                                                                                <img class="" src="assets/img/greenline/bus_steering.png">
                                                                            </div>
                                                                        <div *ngFor="let rows of seatMobile" class="" >
                                                                            <div class="seat-row">
                                                                                <div  *ngFor="let sdata of rows"    class="seat u-ib-sidebar ">
                                                                                    <div  *ngIf="sdata.space_type =='business' || sdata.space_type =='conductor'" class="single-seat" (click)="seatSelect($event,selectedBus, sdata.id,sdata.space_type)" [ngClass]="(sdata.status =='available')? '':'unavailable'">
                                                                                        <div class="left"></div>
                                                                                        <div class="top"></div>
                                                                                        <div class="right"></div>
                                                                                        <div class="bottom"></div>
                                                                                        <div class="back"></div>
                                                                                        <div class="seat-no">{{sdata.id}}</div>
                                                                                    </div>
                                                                                    <div *ngIf=" sdata.space_type =='aisle' " class="no-single-seat"></div>
                                                                                    <div *ngIf=" sdata.space_type =='skip'" class="no-single-seat"></div>
                                                                                    <div *ngIf=" sdata.space_type =='blank' " class="no-single-seat"></div>
                                                                                    <div *ngIf=" sdata.space_type =='driver'  " class="no-single-seat"></div>
                                                                                    <div *ngIf=" sdata.space_type =='door'  " class="no-single-seat"></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-10 col-md-offset-1">
                                                                <div *ngIf="!dataLoading && !seatErrors"  class="seat-legends row">
                                                                    <div class=" col-md-3 seat-legend u-ib-sidebar _mb-10 ">
                                                                        <div class="single-seat-legend u-ib-sidebar ">
                                                                            <div class="left"></div>
                                                                            <div class="top"></div>
                                                                            <div class="right"></div>
                                                                            <div class="bottom"></div>
                                                                            <div class="back"></div>
                                                                        </div><br>
                                                                        <div class="u-ib-sidebar text">Available</div>
                                                                    </div>
                                                                    <div class=" col-md-3  seat-legend u-ib-sidebar _mb-10">
                                                                        <div class="single-seat-legend u-ib-sidebar unavailable">
                                                                            <div class="left"></div>
                                                                            <div class="top"></div>
                                                                            <div class="right"></div>
                                                                            <div class="bottom"></div>
                                                                            <div class="back"></div>
                                                                        </div><br>
                                                                        <div class="u-ib-sidebar text">Your Seat</div>
                                                                    </div>
                                                                    <div class=" col-md-3 seat-legend u-ib-sidebar">
                                                                        <div class="single-seat-legend u-ib-sidebar selected">
                                                                            <div class="left"></div>
                                                                            <div class="top"></div>
                                                                            <div class="right"></div>
                                                                            <div class="bottom"></div>
                                                                            <div class="back"></div>
                                                                        </div><br>
                                                                        <div class="u-ib-sidebar text">Occupied</div>
                                                                    </div>
                                                                </div>
                                                                <!-- <div  *ngIf="seatsSelected.length > 0"  class="col-md-3  _mb-10">
                                                                    <div  class="right-wing u-ib-sidebar visible-lg   u-v-align-middle">
                                                                        <div class="c-price-display u-text-ellipsis " title=""><span class="icon"><i
                                                                                    class="ixi-icon-inr icon" aria-hidden="true"></i></span>
                                                                                    <span  class="text-green price-labels-lg"> Ksh {{totalFare}}</span>
                                                                        </div>
                                                                    </div>
                                                                </div> -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="_mt-10">
                                                      <div *ngIf="seatsSelected.length > 0" class="sidebar-footer ">
                                                          <div class="footer-content">
                                                            <span  class="text-green price-labels-lg"> Ksh {{totalFare}}</span>
                                                          </div>
                                                          <!-- <div   *ngIf="seatsSelected.length > 0"  class="col-md-3  _mb-10">
                                                              <div  class="right-wing u-ib-sidebar hidden-sm hidden-md hidden-lg u-v-align-middle">
                                                                  <div class="c-price-display u-text-ellipsis " title="">
                                                                    <span class="icon">
                                                                      <i class="ixi-icon-inr icon" aria-hidden="true"></i>
                                                                    </span>
                                                                  </div>
                                                              </div>
                                                          </div> -->
                                                          <button type="button" (click)="submitForm(selectedBus)" class="sidebar-button button-action button--orange button">Proceed to book </button>
                                                      </div>

                                                    </div>
                                                    <span (click)="toggleShow(selectedBus)" class="close-seat-button "><i class="fa fa-times fa-2x" aria-hidden="true"></i></span>
                                                    <div class="u-clear"></div>
                                                </div>
                                                </div>
                                        </div>
                                    </div>
                                    <div class="focus{{result.id}} focusBookBtn{{result.id}}"></div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div id="multiselect-error" class="col-xs-12 rides-list" data-group="direct"
                                style="display: none">
                                <div class="search-result">
                                    <div class="search-result__not-found">
                                        <div class="search-result-suggestions--cities"></div>
                                        <div class="search-result__not-found__text">Unfortunately there
                                            are no trips on this route on
                                            the selected date. Please adjust the date of the trip to get
                                            results.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                <div  class="col-md-3 visible-lg ">
                  <div class="theme-search-results-item _br-3 _mt-20 _mb-10 theme-search-results-item-bs theme-search-results-item-lift theme-search-results-item-grid">
                      <img class="_mb-auto img-responsive"  src="assets/img/brands/qrcode.png" >
                        <br>
                    <div class="theme-search-results-item-grid-body">
                      <a class="theme-search-results-item-mask-link" href="#"></a>
                      <div class="theme-search-results-item-grid-header">
                        <h5 class="theme-search-results-item-title">A travel guide in your pocket</h5>
                      </div>
                      <div class="theme-search-results-item-grid-caption">
                        <div class="row" data-gutter="10">
                          <div class="col-xs-9 ">
                            <div class="theme-search-results-item-rating">
                              <ul class="theme-search-results-item-car-list">
                                <li>
                                  <i class="fa fa-check"></i>Fast search
                                </li>
                                <li>
                                  <i class="fa fa-check"></i>One click booking
                                </li>
                                <li>
                                  <i class="fa fa-check"></i>Download ticket
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="theme-search-results-item-grid-header">
                        <h5 class="theme-search-results-item-title">Download the Buupass app</h5>
                        Scan QR code to download
                      </div>
                    </div>
                  </div>
                    <!-- <div class="theme-search-results-item _br-3 _mb-10 theme-search-results-item-bs theme-search-results-item-lift theme-search-results-item-grid">
                      <div class="banner _h-20vh _h-mob-30vh banner-">
                        <img class="banner-bg"  src="assets/img/company1/banner.jpg" >
                      </div>
                      <div class="theme-search-results-item-grid-body">
                        <a class="theme-search-results-item-mask-link" href="#"></a>
                        <div class="theme-search-results-item-grid-header">
                          <h5 class="theme-search-results-item-title">Studio Saint Germain des Pres / Odeon</h5>
                        </div>
                        <div class="theme-search-results-item-grid-caption">
                          <div class="row" data-gutter="10">
                            <div class="col-xs-9 ">
                              <div class="theme-search-results-item-rating">
                                <ul class="theme-search-results-item-rating-stars">
                                  <li class="active">
                                    <i class="fa fa-star"></i>
                                  </li>
                                  <li class="active">
                                    <i class="fa fa-star"></i>
                                  </li>
                                  <li class="active">
                                    <i class="fa fa-star"></i>
                                  </li>
                                  <li class="active">
                                    <i class="fa fa-star"></i>
                                  </li>
                                  <li class="active">
                                    <i class="fa fa-star"></i>
                                  </li>
                                </ul>
                                <p class="theme-search-results-item-rating-title">159 reviews</p>
                              </div>
                            </div>
                            <div class="col-xs-3 ">
                              <div class="theme-search-results-item-price">
                                <p class="theme-search-results-item-price-tag">$133</p>
                                <p class="theme-search-results-item-price-sign">night</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> -->
                  </div>
        </div>
       </div>


</div>
</div>

</div>
      <div class="theme-page-section margin-grey theme-page-section-white">
        <div class="container">
          <div class="row row-col-static" id="sticky-parent">

            <div class="col-md-11">

              <div class="theme-search-results _mb-10">

              </div>
            </div>
          </div>
        </div>
      </div>

<app-footer></app-footer>
