import { Component, OnInit } from '@angular/core';
import { formatDate } from '@angular/common';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-return-date',
  templateUrl: './return-date.component.html',
  styleUrls: ['./return-date.component.css']
})
export class ReturnDateComponent implements OnInit {

  displayMonths = 12;
  today=new Date();
  bsValue = new Date();
  bsInlineRangeValue: Date[];
  ngOnInit(): void {
  }


  constructor(
    private router: Router,
    private dataService: DataService
  ) {

  }

  onValueChange(value: Date): void {
    const date =formatDate(value,"yyyy-MM-dd","en-US");
    console.log(date);
    this.dataService.changeReturnMessage(date);
    this.router.navigateByUrl('/home');
  }

}
