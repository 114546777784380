
<div class="hidden-sm hidden-md hidden-lg ">
    <app-nav ></app-nav>
</div>
<div class="visible-lg">
  <app-headerhome ></app-headerhome>
</div>
<div >
  <div class="MainView _pos-r">
    <div class="hidden-sm hidden-md hidden-lg ">
        <app-search-form></app-search-form>
    </div>
      <div  class="theme-hero-area-body _mt-90 visible-lg  _w-f buupass-container" >
            <div class="theme-search-area-tabs ">
              <div class="theme-search-area-tabs-header text-center  _mr-20 mt-10">
                <h1 class="  banner-text">
                  <div class=" hidden-xs">
                    <h1>Welcome to BuuPass</h1>
                    The easiest way to book bus tickets in Kenya.
                  </div>
                    <img class="hidden-sm hidden-md hidden-lg _ml-50" src="assets/img/company1/logo-mobile.jpg" alt="buupass.com" alt="Image Alternative text" title="Image Title"/>
                </h1>
              </div>
              <div class="tabbable " #stickyForm  [ngClass]="sticky ? 'sticky':'' " >
                <div class="tab-content   _bg-w tab-content-curved">
                  <div class="tab-pane active" id="SearchAreaTabs-4" >
                    <div class="theme-search-area " >
                        <form  autocomplete="off" novalidate [formGroup]="reactiveForm" (ngSubmit)="submitForm()">
                          <div class="row" >
                                <div class="col-md-3">
                                    <app-from formControlName="pickup"
                                      [options]="data"
                                      [ngClass]="{ 'ng-invalid-custom': submitted && f.pickup.errors }"
                                      [placeholder]="fromPlaceholder"
                                      [isReadOnly]="isReadOnly">
                                    </app-from>
                                    <span *ngIf="submitted && f.pickup.errors" [@slideInOut] class="invalid-select">Please enter depature location
                                      <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                                    </span>
                                </div>
                                <div class="col-md-3">
                                    <app-dropdown
                                      formControlName="dropoff"
                                      [dropdownoptions]="data"
                                      [ngClass]="{ 'ng-invalid-custom': submitted && f.dropoff.errors }"
                                      [placeholder]="toPlaceholder"
                                      [isReadOnly]="isReadOnly">
                                    </app-dropdown>
                                    <span *ngIf="submitted && f.dropoff.errors" [@slideInOut] class="invalid-select">Please enter arrival location
                                      <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                                    </span>
                                </div>
                                <div class="col-md-2">
                                  <div class="theme-search-area-section first theme-search-area-section-curved theme-search-area-section-bg-white  theme-search-area-section-mr">
                                    <div class="theme-search-area-section-inner">
                                      <i class="theme-search-area-section-icon fas fa-calendar-alt"></i>
                                      <input type="text"
                                      formControlName="traveldate"
                                      placeholder="Travel Date"
                                      [minDate]="bsValue"
                                      (bsValueChange)="onTravelDateChange($event)"
                                      class="theme-search-area-section-input "
                                      bsDatepicker
                                      [bsConfig]="{ isAnimated: true,adaptivePosition: true, dateInputFormat: 'DD-MM-YYYY' }"
                                      >
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-2 ">
                                <div class="theme-search-area-section theme-search-area-section-curved theme-search-area-section-fade-white">
                                  <div class="theme-search-area-section-inner theme-search-area-section-inner-return">

                                            <div *ngIf="returning" class="theme-search-area-section-inner returndate">
                                              <i class="theme-search-area-section-icon fas fa-calendar-alt"></i>

                                              <i [ngClass]="( isClicked ) ? 'hide-returnicon':''" class="theme-search-area-section-icon lin lin-calendar "></i>
                                              <input  autocomplete="off" type="text"
                                              placeholder="DD-MM-YYYY"
                                              class="theme-search-area-section-input"
                                              formControlName="returndate"
                                              [minDate]="today"
                                              #dp="bsDatepicker"
                                              [isOpen]="isOpen"
                                              bsDatepicker
                                              [bsConfig]="{ isAnimated: true,adaptivePosition: true, dateInputFormat: 'DD-MM-YYYY' }"
                                              >

                                              <i (click)="closeReturn()" [ngClass]="( isClicked ) ? 'hide-returnicon':''"  class="times-icon  fa fa-times"></i>
                                      </div>
                                      <button *ngIf="!returning" (click)="returnClicked()" class="flix-btn-arrow  DatePlaceholder__button___3XZ5p" type="button"> <i class=" icon-flix fa fa-plus "></i> Add Return
                                      </button>
                                </div>
                              </div>
                            </div>
                                <div class="col-md-2">
                                  <button type="submit"  class="theme-search-area-submit btn-block theme-search-area-submit-curved  theme-search-area-submit-glow">SEARCH</button>
                                </div>
                        </div>
                        </form>
                  </div>
                  <div class="text-center" style="">
                    <img  height="60" alt="" class="text-center " src="assets/img/pay_via.png">
                </div>
                </div>
              </div>
              </div>
          </div>
      </div>
    <div class="theme-hero-area">
        <div class="theme-hero-area-body">
          <div class="theme-page-section-buupass ">
            <div class="buupass-container">
              <div class="wa_0_1pykh">
                <div class="">
                    <div class="_mt-30 wf_bn_1op5D wf_w7_3_zC4 wf_x4_TJbnM">
                        <h3 class="q5_bc_2ozg0">
                          <span>These bus operators trust us.</span> <span>We sell their tickets all in one
                                place.
                          </span>
                        </h3>
                    </div>
                    <div class="wf_bn_1op5D wf_w7_3_zC4 wf_yc_2N4u5">
                        <div class="q5_q9_28ZkV">
                            <div class="q5_rb_VniSv"><img src="assets/img/images/easy_coach.png"
                                    alt="Easy Coach" height="36" loading="lazy"></div>
                            <div class="q5_rb_VniSv"><img src="assets/img/images/periska.png"
                                    alt="Crown Bus" height="36" loading="lazy"></div>
                            <div class="q5_rb_VniSv"><img src="assets/img/images/modern_coast.png"
                                    alt="Modern Coast" height="36" loading="lazy"></div>
                            <div class="q5_rb_VniSv"><img src="assets/img/images/greenline.png"
                                    alt="Greenline" height="36" loading="lazy"></div>
                            <div class="q5_rb_VniSv"><img src="assets/img/images/praxigon.png"
                                    alt="East Africa Shuttles" height="36" loading="lazy"></div>
                            <div class="q5_rb_VniSv"><img src="assets/img/images/pride.png"
                                    alt="Pride" height="36" loading="lazy"></div>
                            <div class="q5_rb_VniSv"><img src="assets/img/images/prince.png" alt="SNCF"
                                    height="36" loading="lazy"></div>
                        </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  <app-footer></app-footer>
