<div id="tophome"></div>
<div class="header-mobile">
    <nav class="mobile-nav">
      <div class="mobile-item-cntnr ">
        <a href="https://beta.buupass.com">
          <div class="mobile-item " data-index="2">
              <div class="mobile-text">  LOGGED IN AS {{user.email}}</div>
          </div>
        </a>
      </div>

        <div *ngIf="!isLoggedIn" class="mobile-item-cntnr pull-right">
            <a    role="button" href="http://v2.agency.buupass.com/dashboard">
              <i class="fas fa-sign-out-alt"></i> Dashboard
            </a>
          <a (click)="logoutUser()" role="button" >
            <div class="mobile-item" data-index="0">

                <div class="mobile-text"><i class="fas fa-sign-out-alt"></i> logout</div>
            </div>
          </a>
        </div>
        <div class="nav-border"></div>
    </nav>
</div>
