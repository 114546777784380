import { Component, OnInit } from '@angular/core';
import {style, animate, transition, trigger, group} from '@angular/animations';
import { SharedscrollstatusService } from 'src/app/services/sharedscrollstatus.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../services/user.service'
import { User } from '../../models/index'
import { Error } from '../../models/index'
@Component({
  selector: 'app-headerhome',
  templateUrl: './headerhome.component.html',
  styleUrls: ['./headerhome.component.css'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({
          opacity:0,
          transform: 'translateX(-100%)'
        }),
        group([
          animate(500, style({opacity:1})),
          animate('500ms ease-in', style({transform: 'translateX(0%)'}))
        ])

      ]),
      transition(':leave', [
        group([
          animate(500, style({opacity:0})),
          animate('500ms ease-in', style({transform: 'translateX(-100%)'}))
        ])

      ])
    ])
  ]
})
export class HeaderhomeComponent implements OnInit {
  public toggle:boolean;
    signupForm: FormGroup;
    startForm: FormGroup;
    loginForm: FormGroup;
    resetForm: FormGroup;
    submitted=false;
    loginsubmitted=false;
    is_loggedin=false;
    success:boolean=false;
    successLogin:boolean=false;
    loadingStart:boolean = false;
    loadingSignup:boolean = false;
    loadingLogin:boolean = false;
    resetsubmitted:boolean = false;
    loadingReset:boolean = false;
    successReset:boolean = false;
    isHidden:boolean = true;
    startEmail:any;
    resetErrMsg:any;
    resetSuccessMsg:any;
    resMsg:any;
    res:any;
    resLogin:any;
    isLoggedIn:any;
    user:User;
    api_error:Error;
    fieldTextType: boolean;
    api_error_login:any;
    savedData:any;
    constructor(
      private service:SharedscrollstatusService,
      private _fb: FormBuilder,
      private users:UserService) {
    this.service.currentValue.subscribe(message => this.toggle = message);//subscribe to the currentValue observable.
    this.signupForm = _fb.group({
      first_name:['', Validators.required],
      last_name:['', Validators.required],
      phone_number:['', [Validators.required,Validators.pattern('^(?:254|\\+254|0)(7|1)[0-9]{8}$')]],
      id_number:['',[ Validators.required,Validators.pattern('^[a-zA-Z0-9]*$'),Validators.minLength(7),Validators.maxLength(14)]],
      email:['', [Validators.required,Validators.email]],
      password:['', [Validators.required,Validators.minLength(6)]],
    });
    this.startForm = _fb.group({
      email:['', [Validators.required,Validators.email]],
    });
    this.resetForm = _fb.group({
      email:['', [Validators.required,Validators.email]],
    });
    this.loginForm = _fb.group({
      password:['', Validators.required],
    });
  }

  ngOnInit() {
    this.isLoggedIn = localStorage.getItem('isLoggedIn');
    if(this.isLoggedIn ){
      this.user=new User().deserialize(JSON.parse(localStorage.getItem('user')))
      console.log(this.user);
      this.getUserProfile(this.user);
    }
        this.savedData=localStorage.getItem('a_user');
        console.log(this.savedData);
           try {
             this.user = JSON.parse(atob(this.savedData));
             console.log(this.user)
           } catch(e) {
            //window.location.href = 'http://agency.buupass.com/login'
          }
  }
  logoutUser(){
    localStorage.clear();
    this.isLoggedIn=false;
    window.location.href = 'http://agency.buupass.com/login'
  }
  getUserProfile(user:any){
    this.users.getProfile(user).subscribe(
      response => {
          this.loadingStart=false;
          this.resMsg=response;
          localStorage.setItem('a_user',JSON.stringify(this.resMsg.data));
      },
      error => {
        console.log(error.error);
        localStorage.clear();
        this.isLoggedIn=false;
      }
    );
  }
submitStart(){
  this.loadingStart=true;
  this.loginsubmitted=true;
  if (this.startForm.invalid ) {
      this.loadingStart=false;
      return;
  }
  let user_email=this.startEmail= this.startForm.value.email;
  this.users.checkEmail(user_email).subscribe(
    response => {
        this.loadingStart=false;
        document.getElementById("closeStart").click();
        document.getElementById("openModalButton").click();
        console.log(response);
    },
    error => {
      console.log(error.error.status);
      this.loadingStart=false;
      this.api_error=new Error().deserialize(error.error.errors)
      console.log(this.api_error);
      if(!error.error.status){
        this.signupForm.patchValue({email:user_email });
        document.getElementById("closeStart").click();
        document.getElementById("openSignupButton").click();
      }
    }
  );
}
backToEmail(){
      document.getElementById("openModalStart").click();
      document.getElementById("closePassword").click();
}
initiateResetPassword(){
      document.getElementById("closePassword").click();
      document.getElementById("openModalReset").click();
}
toggleFieldTextType() {
  this.fieldTextType = !this.fieldTextType;
}
 get f() { return this.signupForm.controls; }
submitSignUp(){
    this.submitted = true;
    this.loadingSignup=true;
    // stop here if form is invalid
    if (this.signupForm.invalid ) {
        console.log(this.signupForm);
        return;
    }
    console.log(this.signupForm.value);
    let value = this.signupForm.value;
    let webformdata={
      "first_name": value.first_name,
      "last_name": value.last_name,
      "email": value.email,
      "password": value.password,
      "id_number": value.id_number,
      "phone_number": '+254'+ value.phone_number.substring(1)
    }
    this.users.signUp(webformdata).subscribe(
      response => {
        this.loadingSignup=false;
          this.success = true;
          console.log(response);
          this.resMsg=response;
          this.res= this.resMsg.data.message;
          document.getElementById("closeSignup").click();
          document.getElementById("openSuccessButton").click();
      },
      error => {
          this.success = false;
          this.loadingSignup=false;
          this.user=new User().deserialize(error.error.errors)
          console.log(this.user);
      }
    );
}

submitLogin(){
      this.loginsubmitted=true;
      this.loadingLogin=true;
     if (this.loginForm.invalid ) {
        return;
     }
    let value = this.loginForm.value;
    let webformlogin={
      "password": value.password,
      'email':this.startEmail,
    }
    this.users.login(webformlogin).subscribe(
      response => {
          this.loadingLogin=false;
          this.loginsubmitted=false;
          this.successLogin = true;
          this.resMsg=response;
          if(this.resMsg.data){
            localStorage.setItem('isLoggedIn','true');
            localStorage.setItem('user',JSON.stringify(this.resMsg.data));
            this.user=new User().deserialize(JSON.parse(localStorage.getItem('user')))
            this.isLoggedIn=true;
            document.getElementById("closePassword").click();
          }
      },
      error => {
          this.successLogin = false;
          this.loginsubmitted=false;
          this.loadingLogin=false;
          this.api_error=new Error().deserialize(error.error.errors)
          this.api_error_login=this.api_error[0];
      }
    );
}

submitResetPassword(){
  this.resetsubmitted=true;
  this.loadingReset=true;
 if (this.resetForm.invalid ) {
    return;
 }
console.log(this.resetForm.value);
let value = this.resetForm.value;
let webformReset={
  "email": value.email
}
console.log(webformReset);
this.users.passwordReset(webformReset).subscribe(
  response => {
      this.loadingReset=false;
      this.resetsubmitted=false;
      this.successReset = true;
      this.resMsg=response;
      this.resetSuccessMsg=this.resMsg.data.message;
  },
  error => {
      console.log(error);
      this.successReset = false;
      this.resetsubmitted=false;
      this.loadingReset=false;
      this.api_error= new Error().deserialize(error.error.errors);
      this.resetErrMsg=this.api_error['0'];
      console.log(this.api_error['0']);
  }
);
}
}
