import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class DataService {
  private messageSource = new BehaviorSubject('');
  public travelValueMobile = new BehaviorSubject<string>('');
  public returnValueMobile = new BehaviorSubject<string>('');
  currentMessage = this.messageSource.asObservable();
  traveldateMobileChange = this.travelValueMobile.asObservable();
  returndateMobileChange = this.returnValueMobile.asObservable();

  changeMessage(message: string) {
    this.messageSource.next(message)
  }

  changeTravelMessage(message: string) {
    this.travelValueMobile.next(message)
  }

  changeReturnMessage(message: string) {
    this.returnValueMobile.next(message)
  }

  constructor() { }
}
