import { Component, Output, EventEmitter, Input, ViewChild, AfterViewInit  } from '@angular/core';
import { City } from 'src/app/models/index';
import { TownsService } from 'src/app/services/towns.service';
import { Router } from '@angular/router';
import { FromStatusService } from 'src/app/services/from-status.service';
@Component({
  selector: 'app-mobile-from',
  templateUrl: './mobile-from.component.html',
  styleUrls: ['./mobile-from.component.css']
})
export class MobileFromComponent implements AfterViewInit {
  @Input() searchModel;
  @Output() searchModelChange: EventEmitter<any> = new EventEmitter();
  cities:any;
  selectedCity: City;

  @ViewChild("fromInputFocus") fromInputFocus;

  constructor(
    private router: Router,
    private townService: TownsService,
    private fromService:FromStatusService,
  ) {
      this.townService.currentValue.subscribe(message => {
        this.cities =message;
      });
      if(this.cities.length == 0){
        this.cities=JSON.parse(atob(localStorage.getItem('cities')));
        console.log(this.cities);
      }
   }
  ngAfterViewInit(): void {
    //this.fromInputFocus.nativeElement.focus();
  }
  cancel() {
    this.router.navigateByUrl('/home');
  }
  onSelect(city: City): void {
    this.selectedCity = city;
    this.fromService.changeSelectedMobileValue(city.name);
    this.router.navigateByUrl('/home');
  }
  updateSearchModel(value) {
    this.searchModel = value;
    this.searchModelChange.emit(this.searchModel);
  }

}
