<nav
  class="navbar bHTikb sticky-top navbar-default navbar-fixed-top buupass-box navbar-inverse navbar-theme navbar-theme-abs navbar-theme-transparent navbar-theme-border"
  id="main-nav">
  <div class="nav-overlay"></div>

  <div class="navbar-inner nav">
    <div class="navbar-header">
      <button class="navbar-toggle collapsed" data-target="#navbar-main" data-toggle="collapse" type="button"
        area-expanded="false">
        <span class="sr-only">Toggle navigation</span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
      <a  class="navbar-brand-logo _mt-20" href="/home/{{savedData}}">
        <img  src="assets/img/company1/logo-agency.png" alt="buupass.com" width="70" alt="Image Alternative text" title="Image Title"/>
      </a>
    </div>
    <div class="collapse navbar-collapse" id="navbar-main">

      <ul class="nav navbar-nav pd-nav">
        <li class="active">
          <a href="index.html" class="headerAccount" role="button">
          LOGGED IN AS {{user.email}}
          </a>
        </li>
      </ul>
      <ul class="nav navbar-nav navbar-right">
        <li *ngIf="!isLoggedIn">
          <a    role="button" href="http://v2.agency.buupass.com/dashboard">
            <i class="fas fa-sign-out-alt"></i> Dashboard
          </a>
        </li>
        <li *ngIf="!isLoggedIn">
          <a   (click)="logoutUser()" role="button">
            <i class="fas fa-sign-out-alt"></i> logout
          </a>
        </li>


      </ul>
    </div>
  </div>

</nav>
