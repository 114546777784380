import { Component, OnInit } from '@angular/core';
import { User } from '../../models/index'
@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {
  savedData:any;
  user:User;
  isLoggedIn=false;
  constructor() { }

  ngOnInit(): void {
    this.savedData=localStorage.getItem('a_user');
    console.log(this.savedData);
       try {
         this.user = JSON.parse(atob(this.savedData));
         console.log(this.user)
       } catch(e) {
        //window.location.href = 'http://agency.buupass.com/login'
      }
  }

  logoutUser(){
    localStorage.clear();
    this.isLoggedIn=false;
    window.location.href = 'http://agency.buupass.com/login'
  }

}
