import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { retry } from 'rxjs/operators'
import  { environment } from '../../environments/environment'

const httpOptions = {
  headers: new HttpHeaders({
   'Authorization':  'Api-Key '+ environment.api_key
  })
};

@Injectable({
  providedIn: 'root'
})
export class ConfirmService {

  constructor(
    private http:HttpClient
  ) { }


  confirmMpesa(request:any){
    const body = new HttpParams()
    .set('booking_id',request.booking_id)
    .set('transaction_id',request.transaction_id)
    .set('trip_id',request.trip_id)
    .set('transaction_verify',request.transaction_verify)
    .set('operator_booking_id',request.operator_booking_id)
    .set('payee[phone]',request.phone)
    .set('payee[confirmation]',request.confirmation)
    .set('payee[full_name]',request.full_name)
    .set('payee[email]',request.email);
    return this.http.post(environment.paymentQuery,body.toString(),{
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
    });
  }

  confirmV2(booking_id:any){
   
      return this.http.get(environment.v2confirm  + booking_id,httpOptions)
      .pipe(
        retry(3), 
      );
  }

}
