import { Component, OnInit } from '@angular/core';
import {style, state, animate, transition, trigger, group} from '@angular/animations';
import { SharedscrollstatusService } from 'src/app/services/sharedscrollstatus.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-headermain',
  templateUrl: './headermain.component.html',
  styleUrls: ['./headermain.component.css'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({
          opacity:0,
          transform: 'translateX(-100%)'
        }),
        group([
          animate(500, style({opacity:1})),
          animate('500ms ease-in', style({transform: 'translateX(0%)'}))
        ])

      ]),
      transition(':leave', [
        group([
          animate(500, style({opacity:0})),
          animate('500ms ease-in', style({transform: 'translateX(-100%)'}))
        ])

      ])
    ])
  ]
})
export class HeadermainComponent implements OnInit {

  public toggle:boolean;
    signupForm: FormGroup;
    startForm: FormGroup;
    submitted=false;
    is_loggedin=false;
    startEmail:any;
  constructor(
      private service:SharedscrollstatusService,
      private _fb: FormBuilder) {
    this.service.currentValue.subscribe(message => this.toggle = message);//subscribe to the currentValue observable.
    this.signupForm = _fb.group({
      fname:['', Validators.required],
      lname:['', Validators.required],
      phoneno:['', Validators.required],
      idno:['', Validators.required],
      email:['', Validators.required],
      password:['', Validators.required],
    });

    this.startForm = _fb.group({
      email:['', [Validators.required,Validators.email]],
    });
  }

  ngOnInit() {
  }

  submitStart(){
    if (this.startForm.invalid ) {
        return;
    }

    this.startEmail=this.startForm.value;

    console.log(this.startForm.value);
    document.getElementById("closeStart").click();
    document.getElementById("openModalButton").click();

  }

  submitSignUp(){
      this.submitted = true;
      console.log(this.signupForm.value);
      let value = this.signupForm.value;
      let webformdata={
        "first_name": value.fname,
        "last_name": value.lname,
        "email": value.email,
        "password": value.password,
        "id_number": value.idno,
        "phone_number": value.phoneno
      }
    }


}
