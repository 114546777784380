import { Component, ViewChild, ElementRef, HostListener, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { trigger, transition, animate, style } from '@angular/animations'
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {Destinations } from '../models/index'
import {HomeService,DataService } from '../services/index'
import { formatDate  } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { SharedscrollstatusService } from '../services/sharedscrollstatus.service';
import { PassDestinationValueService} from '../services/pass-destination-value.service'
import { PassDepatureValueService} from '../services/pass-depature-value.service'
import { TownsService } from '../services/towns.service'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({transform: 'translateY(-100%)'}),
        animate('2000ms ease-in', style({transform: 'translateY(0%)'}))
      ]),
      transition(':leave', [
        animate('2000ms ease-in', style({transform: 'translateY(-100%)'}))
      ])
    ])
  ]

})
export class HomeComponent implements AfterViewInit  {
  bsInlineValue = new Date();
  reactiveForm: FormGroup;
  fromPlaceholder="From";
  toPlaceholder="To";
  destinationLoading=false;
  pickup='';
  dropoff='';
  traveldate='';
  daterange='';
  destinations: Destinations[] = [];
  destination: any = {};
  res: any = {};
  today=new Date();
  bsValue = new Date();
  public pickplaceholder: string = 'Depature';
  public destplaceholder: string = 'Destination';
  public historyHeading: string = 'Recently selected';
  keyword = 'name';
  isClicked=false;
  isPickupClicked=false;
  returning=false;
  isReadOnly=false;
  types= [];
  //traveldate:any;
  data = [];
  msg = '';
  formdata={};
  submitted = false;
  loading: boolean;
  form = new FormGroup({
    traveldate: new FormControl(new Date()),
  });

  defaultImage="assets/img/cities/placeholder.jpg";

  mombasa="assets/img/cities/mombasa.jpg";
  nairobi="assets/img/cities/nairobi.jpg";
  kisumu="assets/img/cities/kisumu.jpg";
  kampala="assets/img/cities/kampala.jpg";
  dar="assets/img/cities/dar.jpg";
  bungoma="assets/img/cities/bungoma.jpg";
  eldoret="assets/img/cities/eldoret.jpg";

  @ViewChild('stickyForm') menuElement: ElementRef;
  sticky: boolean = false;
  isOpen=false;
  elementPosition: any;
  agent:any;
  constructor(
    private service : HomeService,
    private dataService : DataService,
    private router:Router,
    private _fb: FormBuilder,
    private townService:TownsService,
    private serviceScroll: SharedscrollstatusService,
    private serviceDestination: PassDestinationValueService,
    private serviceDepature:PassDepatureValueService,
    private cdRef: ChangeDetectorRef,
    private route: ActivatedRoute,
        ) {
          this.reactiveForm = _fb.group({
            pickup:[null, Validators.required],
            dropoff:[null, Validators.required],
            daterange:[formatDate(new Date(),"yyyy-MM-dd","en")+':One Way',Validators.required],
            traveldate:[new Date(), Validators.required],
            returndate:[null, null]
          });
    }
  ngAfterViewInit(){
    localStorage.setItem('search', '');
    this.loadDestinations();
    this.formControlValueChanged();
    this.cdRef.detectChanges();
    let top = document.getElementById('tophome');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }

  ngOnInit() {
    this.agent = this.route.params.subscribe(params => {
      let data= params['id'];
      console.log(data);
      if(!data){

        window.location.href = 'http://.agency.buupass.com/login'
      }
      try {
        let agent=JSON.parse(atob(data));
         localStorage.setItem('a_user',data);
      } catch(e) {
        console.log('hereExeptio');
        window.location.href = 'http://agency.buupass.com/login'
      }
    });
  }

  onTravelDateChange = (event) =>{
    var traveldate = formatDate(event,"yyyy-MM-dd","en-KE");
    this.today = event;
  }

  @HostListener('window:scroll', ['$event'])
    handleScroll(event){
      const windowScroll = window.pageYOffset;
      if(windowScroll >= this.elementPosition){
        this.menuElement.nativeElement.setAttribute('style','position:fixed')
        this.sticky = true;
      } else {
        this.sticky = false;

      }
      this.serviceScroll.changeValue(this.sticky);
    }

     get f() { return this.reactiveForm.controls; }

     formControlValueChanged() {
      this.reactiveForm.get('pickup').valueChanges.subscribe(
          (mode: string) => {
              //this.serviceDepature.changeValue(mode);
          });

          this.reactiveForm.get('dropoff').valueChanges.subscribe(
            (mode: string) => {
              //this.serviceDestination.changeValue(mode);
            });
  }

  onFocused(e){
    this.isClicked=true;
  }

  returnClicked =() =>{
    this.returning=true;
    this.isOpen=true;
  }

  closeReturn(){
    this.returning=false;
  }

  onClosed(e){
    this.isClicked=false;
  }

  onPickupFocused(e){
    this.isClicked=true;
    this.isPickupClicked=true;

  }
  onPickupClosed(e){
    this.isClicked=false;
    this.isPickupClicked=false;
  }

  submitForm() {


    if(this.reactiveForm.value.returndate == null){
      this.returning =false;
      this.isOpen=false;
    }
      this.submitted = true;
      // stop here if form is invalid
      if (this.reactiveForm.invalid ) {
          return;
      }

      let value = this.reactiveForm.value;
      var traveldate = formatDate(value.traveldate,"yyyy-MM-dd","en-KE");
      var returndate = formatDate(value.returndate,"yyyy-MM-dd","en-KE");

      value.traveldate=traveldate;
      if(this.returning){
         value.returndate=returndate;
      }


    this.formdata={
      'pickup' : value.pickup,
      'dropoff' : value.dropoff,
      'pickupname' : value.pickup,
      'dropoffname' : value.dropoff,
      'traveldate' : traveldate,
      'returndate': returndate,
      'returning' : this.returning
    }
    console.log('serahsb s ');
    let p=btoa(JSON.stringify(this.formdata));
    this.dataService.changeMessage(p);
    this.router.navigate(['/booking'])
  }

  nairobiKisumu(){

      let kisumu={
          'pickup' : 'Nairobi',
          'dropoff' : 'Kisumu',
          'pickupname' :'Nairobi',
          'dropoffname' : 'Kisumu',
          'traveldate' : this.today,
          'returndate': '',
          'returning' : this.returning
        }

        let p=btoa(JSON.stringify(kisumu));
        this.dataService.changeMessage(p);
        this.router.navigate(['/booking'])
  }

  nairobiKitale(){
    let kitale={
        'pickup' : 'Nairobi',
        'dropoff' : 'Kitale',
        'pickupname' :'Nairobi',
        'dropoffname' : 'Kitale',
        'traveldate' : this.today,
        'returndate': '',
        'returning' : this.returning
      }

      let p=btoa(JSON.stringify(kitale));
      this.dataService.changeMessage(p);
      this.router.navigate(['/booking'])
  }

nairobiBungoma() {
  let bungoma={
      'pickup' : 'Nairobi',
      'dropoff' : 'Bungoma',
      'pickupname' :'Nairobi',
      'dropoffname' : 'Bungoma',
      'traveldate' : this.today,
      'returndate': '',
      'returning' : this.returning
    }

    let p=btoa(JSON.stringify(bungoma));
    this.dataService.changeMessage(p);
    this.router.navigate(['/booking'])
}

nairobiBusia() {
  let busia={
      'pickup' : 'Nairobi',
      'dropoff' : 'Busia',
      'pickupname' :'Nairobi',
      'dropoffname' : 'Busia',
      'traveldate' : this.today,
      'returndate': '',
      'returning' : this.returning
    }

    let p=btoa(JSON.stringify(busia));
    this.dataService.changeMessage(p);
    this.router.navigate(['/booking'])
}
  nairobiMombasa(){

    let mombasa={
        'pickup' : 'Nairobi',
        'dropoff' : 'Mombasa',
        'pickupname' :'Nairobi',
        'dropoffname' : 'Mombasa',
        'traveldate' : this.today,
        'returndate': '',
        'returning' : this.returning
      }
      let p=btoa(JSON.stringify(mombasa));
      this.dataService.changeMessage(p);
      this.router.navigate(['/booking'])
  }
  nairobiKampala(){

    let kampala={
        'pickup' : 'Nairobi',
        'dropoff' : 'Kampala',
        'pickupname' :'Nairobi',
        'dropoffname' : 'Kampala',
        'traveldate' : this.today,
        'returndate': '',
        'returning' : this.returning
      }

      let p=btoa(JSON.stringify(kampala));
      this.dataService.changeMessage(p);
      this.router.navigate(['/booking'])
  }

  private loadDestinations() {
    this.destinationLoading=true;

    this.service.getDestinations().subscribe(
      response => {
        let list: string[] = [];
        this.res = response;
          this.res.data.all_cities.forEach(element => {
              list.push(element.name);
          });
        this.data=list;
        this.townService.changeValue(this.res.data.all_cities);
        let cities=btoa(JSON.stringify(this.res.data.all_cities));
        localStorage.setItem('cities', cities);
        this.types=this.res.data;
        this.destinationLoading=false;
      },
      error => {

      }
    );

  }

}
