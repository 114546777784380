import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmService } from '../services/index'
import { Error } from '../models/index'
@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.css']
})
export class ConfirmComponent implements OnInit {
  sub:any;
  confirmData:any;
  res:any;
  isError:boolean;
  errorMessage:any;
  successMessage:any;
  isWaiting:boolean;
  waitingMessage:any;
  api_error:Error;
  started=0;
  int1:any;
  int2:any;
  int3:any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private service: ConfirmService
  ) { }
  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      let data= params['id'];
      this.confirmData=JSON.parse(atob(data));
      this.confirmMpesa(this.confirmData);
    });
  }
  confirmMpesa(dataReceived:any){
    this.isWaiting=true;
    this.waitingMessage="Processing your payments please wait.....";
        let pay_id ='';
        pay_id = dataReceived.data.booking_id;

    this.service.confirmV2(pay_id).subscribe(
      response => {
        this.res=response;
        var self = this;
        if(this.res.status =='confirmed'){
          this.isWaiting=false;
          this.successMessage="Payment processed successfully";
          // if(){
          //
          // }
          let f=btoa(JSON.stringify(dataReceived))

          clearInterval(self.int1);
          clearInterval(self.int2);
          clearInterval(self.int3);
          this.router.navigate(['/booking-successful', f])
        }else if(this.res.status == 'cancelled' ){
          var self = this;
          this.isWaiting=false;
          this.isError=true;
          this.errorMessage="Sorry your payment request was canceled. Please follow the instructions sent via sms to confirm your booking";
          if (this.res.reason == "validation"){

              setTimeout(function () {
                clearInterval(self.int1);
                clearInterval(self.int2);
                clearInterval(self.int3);
                self.router.navigate(['/index.html']);
              }, 10000);

          }

                    setTimeout(function () {
                      if(self.started==0){
                         self.started=1
                         var i = 1000;
                          self.int1 = setInterval(function () {
                          self.confirmMpesa(dataReceived);
                          i += 1000;
                          if (i >= 25000){
                            clearInterval(self.int1);
                            clearInterval(self.int2);
                            clearInterval(self.int3);
                            this.router.navigate(['/index.html']);
                          } ;
                        }, 10000);
                      }
                  }, 1000);
        }else{
          console.log( this.res);
                this.isWaiting=true;
                this.waitingMessage="Processing your payments please wait.....";
                  var self = this;
                            setTimeout(function () {
                              if(self.started==0){
                                 self.started=1
                                 var i = 1000;
                                 self.int2 = setInterval(function () {
                                  self.confirmMpesa(dataReceived);
                                  i += 1000;
                                  if (i >= 25000){
                                    clearInterval(self.int1);
                                    clearInterval(self.int2);
                                    clearInterval(self.int3);
                                    self.router.navigate(['/index.html']);
                                  } ;
                                }, 10000);
                              }
                          }, 1000);
        }
      },
      error => {
        this.isError=true;
        this.isWaiting=true;
        this.waitingMessage="Processing your payments please wait.....";
        this.api_error=new Error().deserialize(error.error.errors)
        var self = this;
                  setTimeout(function () {
                    if(self.started==0){
                       self.started=1
                       var i = 1000;
                        self.int3 = setInterval(function () {
                        self.confirmMpesa(dataReceived);
                        i += 1000;
                        if (i >= 25000){
                            clearInterval(self.int1);
                            clearInterval(self.int2);
                            clearInterval(self.int3);
                            self.router.navigate(['/index.html']);
                        } ;
                      }, 10000);
                    }
                }, 1000);
      }
    );

  }

}
