import { Component, AfterViewInit, Output, EventEmitter, Input, ViewChild  } from '@angular/core';
import { City } from 'src/app/models/index';
import { TownsService } from 'src/app/services/towns.service';
import { Router } from '@angular/router';
import { DropdownStatusService } from 'src/app/services/dropdown-status.service';
@Component({
  selector: 'app-mobile-to',
  templateUrl: './mobile-to.component.html',
  styleUrls: ['./mobile-to.component.css']
})
export class MobileToComponent implements AfterViewInit {
  @Input() searchModel;
  @Output() searchModelChange: EventEmitter<any> = new EventEmitter();
  cities:any;
  selectedCity: City;
    @ViewChild("toInputFocus") toInputFocus;
  constructor(
    private router: Router,
    private townService: TownsService,
    private destinationService:DropdownStatusService,
  ) {
      this.townService.currentValue.subscribe(message => {
        this.cities =message;
      });
      if(this.cities.length == 0){
        this.cities=JSON.parse(atob(localStorage.getItem('cities')));
        console.log(this.cities);
      }

      console.log(this.cities.length);
   }
   ngAfterViewInit(): void {
     //this.toInputFocus.nativeElement.focus();
   }
   cancel() {
     this.router.navigateByUrl('/home');
   }
   onSelect(city: City): void {
     this.selectedCity = city;
    this.destinationService.setDropOff(city.name);
     this.router.navigateByUrl('/home');
   }
   updateSearchModel(value) {
     this.searchModel = value;
     this.searchModelChange.emit(this.searchModel);
   }
}
