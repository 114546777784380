<div class="hidden-sm hidden-md hidden-lg ">
    <app-nav ></app-nav>
</div>
<div class="visible-lg">
  <app-headerhome ></app-headerhome>
</div>
<div class="theme-page-section theme-page-section-xl theme-page-section-gray">
      <div class="container">
        <div class="row">
          <div class="col-md-11 col-md-offset-1">
            <div class="theme-about-us">
              <div class="theme-about-us-section sticky-parent" style="position: relative;">
                <div class="row row-col-static row-col-reverse">

                  <div class="col-md-12">
                    <div class="theme-about-us-section-body">
                      <h1 class="banner-text-about">Trains, Buses and Flights—All in One Platform</h1><br>
                      <h2 class="banner-text-about-h2">About us</h2>
                      <p>BuuPass is Kenya’s largest online bus, train and flight ticketing platform that has transformed
                      bus travel in the country by bringing ease and convenience to millions of Kenyan travellers.
                      We work with the country’s leading bus, train and flight operators to bring you the convenience
                      of digital bookings. </p>
                      <h2 class="banner-text-about-h2">Book your next trip with BuuPass </h2>
                      <ol style="list-style: decimal;">
                          <li class="list-item-about">Enter your departure and arrival destinations and travel dates.</li>
                          <li class="list-item-about">Compare the different train, bus and flight routes available between the two destinations (feel free to filter for cheapest, fastest, recommended).</li>
                          <li class="list-item-about">Choose the best route for you and book your tickets on our website..</li>
                          <li class="list-item-about">Pay using your preferred payment method and get SMS tickets.</li>
                          <li class="list-item-about">Have a great trip!.</li>
                      </ol>
                      <h2 class="banner-text-about-h2">Why Us?</h2>
                      <ol style="list-style: circle;">
                          <li class="list-item-about">Enter your departure and arrival destinations and travel dates.</li>
                          <li class="list-item-about">Compare the different train, bus and flight routes available between the two destinations (feel free to filter for cheapest, fastest, recommended).</li>
                          <li class="list-item-about">Choose the best route for you and book your tickets on our website..</li>
                          <li class="list-item-about">Pay using your preferred payment method and get SMS tickets.</li>
                          <li class="list-item-about">Have a great trip!.</li>
                      </ol>
                      <h2 class="banner-text-about-h2">Our History</h2>
                      <p>
                          BuuPass is driven by young entrepreneurs who are passionate about solving real everyday problems by making
                          simple and powerful solutions. Their idea was born in response to the 2016 Hult Prize Challenge
                          (<a href target="_blank"> www.hultprize.com</a>) a global student competition for social entrepreneurship, which launches students
                          into developing scalable business models in order to solve global challenges. The challenge for 2016
                          was to better connect people to goods, services, and resources. After a year-long process,
                          the diverse team went on to win the prestigious competition with President Bill Clinton, under
                          their former name Magic Bus Ticketing. The BuuPass team is motivated by the idea of transforming
                          inefficient transport systems into a catalyst for growth and impact, in order to connect people to
                          opportunities.
                      </p>

                      <h2 class="banner-text-about-h2">Awards and Recognition</h2>

                      <div class="theme-about-us-section-gallery">
                        <div class="row">
                          <div class="col-md-6">
                              <img class="img-responsive" src="assets/img/company1/hultprizewinner.jpg" class="_mt-20" height="350" />
                              <p>The buupass team celebrates winning $1M USD in seed funding at the 2016 HULT Prize competition.</p>
                          </div>
                          <div class="col-md-6">
                                <h4 class="_pd-20">Other Awards and Recognitions.</h4>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
